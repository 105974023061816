<!--
 * @Description:
 * @Author: YangXin
 * @Date: 2024-08-05 11:32:39
 * @listEditTime: Do not edit
-->
<template>
    <el-form :model="formListMode" :ref="refName" label-width="110px" :rules="rules" >
        <el-form-item  :label="item.label" :prop="item.prop" v-for="(item,index) in formItem"  :key="index" v-if="item.isType" >
            <slot :name="item.slotName"></slot>
        </el-form-item>
    </el-form >
</template>

<script>
export default {
    props:{
      refName:{
        type:String,
        default:()=>{
            return "ruleForm"
        }
      },
        rules:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        formListMode:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        formItem:{
            type:Array,
            default:()=>{
                return []
            }
        },
    },

    methods: {

      /**
       * @Author: YangXin
       * @Description: 表单提交内部方法
       * @Date: 2024-08-06 09:50:23
       * @param {*} formName
       * @return {*}
       */
      submitForm(formName) {
        return new Promise((resolve, reject) => {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              // this.$refs[formName].resetForm(formName);
              resolve({ isType: true });
            } else {
              console.log('error submit!!');
            }
          });
        });
      },

      /**
       * @Author: YangXin
       * @Description: 表单重置内部方法
       * @Date: 2024-08-06 09:50:57
       * @param {*} formName
       * @return {*}
       */
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
}
</script>

<style>

</style>
