import { gsap } from "gsap-trial";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap-trial/ScrollSmoother";
import Hammer from 'hammerjs';
import { Observer } from "gsap/Observer";

import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Draggable } from "gsap/Draggable";


gsap.registerPlugin(ScrollTrigger, Draggable, ScrollToPlugin);

export const gaspMixin = {

  data() {
    return {
      currentTime: new Date().toLocaleString(),
    };
  },
  methods:{
    init() {
      // ScrollSmoother.create({
      //   smooth: 2, // how long (in seconds) it takes to "catch up" to the native scroll position
      //   effects: false, // looks for data-speed and data-lag attributes on elements
      //   smoothTouch: 1.5, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
      // });

      gsap.from(this.$refs["main-header"], { y: -100, duration: 0.4, });
      gsap.from(this.$refs["main-header-top"], { y: -100, duration: 1, });
      // gsap.from(this.$refs["main-body"], { opacity: 0, duration: 0.5, stagger: 0.8 });
      // gsap.from(this.$refs["main-footer"], {y: 200, duration: 1});
      this.handleScroll()
     // this.swipe()
      this.startClock();//其余时间
    },
    EventListener(){

    },

    handleScroll(num) {

        const scrollContainer = this.$refs["engineBoxRef"];
        // gsap.killTweensOf(scrollContainer);
        let totalWidth = scrollContainer.scrollWidth;

        // const animation = gsap.to(scrollContainer, {
        //   x: () => -totalWidth + 1000,
        //   scrollTrigger: {
        //     trigger: scrollContainer,
        //     start: 'top 0',
        //     end:  `+=${totalWidth}`,
        //     scrub: true,
        //     pin: true
        //   }
        // });


        // this.updateAnimation = () => {
        //   totalWidth = scrollContainer.scrollWidth;
        //   animation.vars.x = () => -totalWidth; // 更新目标位置
        //   animation.invalidate(); // 通知 GSAP 刷新动画配置
        // };

        // // 假设有一个方法来监听内容变化
        // this.contentChanged = () => {
        //   this.updateAnimation();
        // };


      // Observer.create({
      //   target: scrollContainer, // Listen to the content for touch events
      //   type: "touch", // Listen for touch and pointer events
      //   tolerance: 10, // How much movement before it counts as a swipe
      //   onRight: () => {
      //     debugger
      //     // Scroll down when swiping right
      //     gsap.to(window, { scrollTo: "+=100", duration: 0.5 });
      //   },
      //   onLeft: () => {
      //     debugger
      //     // Scroll up when swiping left
      //     gsap.to(window, { scrollTo: "-=100", duration: 0.5 });
      //   }
      // });

    },



    swipe() {
      const hammer = new Hammer(this.$refs['main-body']);
      let that = this;
      hammer.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });

      hammer.on('swipeleft', () => {
        gsap.to(window, { scrollTo: "+=1200", duration: 1, ease: "power2" });
      });

      hammer.on('swiperight', () => {
        gsap.to(window, { scrollTo: "-=1200", duration: 1, ease: "power2" });
      });
    },
    startClock() {
      setInterval(() => {
        const now = new Date();
        const daysOfWeek = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
        const dayOfWeek = daysOfWeek[now.getDay()];

        this.currentTime = `${now.toLocaleString()} ${dayOfWeek}`;
      }, 1000);
    },
    gotoCard(divID) {
      gsap.to(window, { duration: 2, scrollTo: "#" + divID });
    },
    gotoUrl(url) {
      window.open(url)
    },
  }


}
