<!--
 * @Description: 
 * @Author: YangXin
 * @Date: 2024-08-01 16:39:23
 * @listEditTime: Do not edit
-->
<template>
  <div class="tab-box">

    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane v-for="(item, index) in tabLabel" :key="index" :label="item.label" :name="item.name" >
        <slot class="table_card" :name="item.name"></slot>
      </el-tab-pane>

    </el-tabs>
  </div>
</template>
<script>
export default {

  props: {
    tabLabel: {
      type: Array,
      default: []
    },
    // activeName: {
    //   type: String,
    //   default: "1"
    // }

  },
  // watch: {
  //   activeName(newVal) {
  //     // 假设每个 tab 对应一个组件名，这里需要根据你的实际情况来调整  
  //     this.currentComponent = `component-${newVal}`;
  //     // 注意：这里的 component-first 和 component-second 需要是已经注册好的组件名  
  //     // 或者你可以使用 Vue 的异步组件或其他方式来动态加载组件  
  //   },
  // },
  data() {
    return {
      activeName: "1"

    }
  },


  methods: {
    handleClick(tab,event) {
      console.log(tab)
      this.activeName = tab.name
      // this.activeName = 
      // this.$emit("handleClick")
    }
  }

}
</script>
<style lang='scss' scoped>
.tab-box {
  // position: relative;
  width: 100%;
  height: 100%;
}
.table_card{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

::v-deep .el-tabs--border-card {
  height: 100%;
}

::v-deep .el-tabs__content {
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
}

::v-deep .el-tab-pane {
  height: 100%;
}
</style>